export default function animaOffice() {
  const ulPaises = document.querySelectorAll(".ul-paises .item-pais");
  const itemsDecorations = document.querySelectorAll("[data-item]");
  const dataRegiao = document.querySelectorAll("[data-pais-regiao]");
  const paisActive = document.querySelectorAll("[data-pais2]");

  //Remove todos os items ativos
  const removePaisSelected = () => {
    dataRegiao.forEach((i) => {
      const itemSvgShow = i.querySelector("[data-svg-arrow-hidden]");
      const itemSvgHidden = i.querySelector("[data-svg-arrow]");
      const itemUl = i.querySelector("[data-item-regiao]");

      itemSvgShow.classList.remove("itemSvgActive");
      itemSvgHidden.classList.remove("itemSvgDisable");
      itemUl.classList.remove("itemUlRegiaoActive");
    });
  };

  const removeItemsDecoration = () => {
    itemsDecorations.forEach((item) => {
      item.classList.remove("item-decoration");
    });
  };

  const removeItemSelect = () => {
    ulPaises.forEach((item) => {
      item.classList.remove("itemOfficeActive");
    });
  };

  const removePais2Active = () => {
    paisActive.forEach((i) => {
      i.classList.remove("itemPaisActive");
    });
  };

  //Compara o se o pais selecionado é o mesmo que deverá ser exibido
  const comparaPaises = (pais) => {
    paisActive.forEach((i) => {
      if (pais === i.dataset.pais2) {
        i.classList.toggle("itemPaisActive");
      } else console.log("País não encontrado");
    });
  };

  //Function animação change color select
  const changeItemSelected = () => {
    ulPaises.forEach((i) => {
      i.addEventListener("click", () => {
        removeItemsDecoration();
        removeItemSelect();
        removePais2Active();
        i.classList.toggle("itemOfficeActive");
        comparaPaises(i.dataset.pais);
        const item = i.querySelector("[data-item]");
        item.classList.toggle("item-decoration");
      });
    });
  };

  //Function animação change endereços
  const changeItemsPaises = () => {
    dataRegiao.forEach((i) => {
      i.addEventListener("click", () => {
        //removePaisSelected();
        const itemSvgShow = i.querySelector("[data-svg-arrow-hidden]");
        const itemSvgHidden = i.querySelector("[data-svg-arrow]");
        const itemUl = i.querySelector("[data-item-regiao]");

        console.log("dataregiao", dataRegiao);
        itemSvgShow.classList.toggle("itemSvgActive");
        itemSvgHidden.classList.toggle("itemSvgDisable");
        itemUl.classList.toggle("itemUlRegiaoActive");

        console.log("capturei o click", itemUl);
      });
    });
  };

  changeItemsPaises();
  changeItemSelected();
}

/*

const removeItemsDecoration = () =>{
    itemsDecorations.forEach(item =>{
        item.classList.remove('item-decoration')
    })
}
 
const removeItemSelect = () =>{
    ulPaises.forEach(item =>{
        item.classList.remove('itemOfficeActive')
    })
}

const changeItemSelected = () =>{
    ulPaises.forEach(i=>{
        i.addEventListener('click',()=>{
            removeItemsDecoration()
            removeItemSelect()
            i.classList.toggle('itemOfficeActive')
            const item = i.querySelector('[data-item]')
            item.classList.toggle('item-decoration')
        })
    })
}

changeItemSelected()


*/



gsap.fromTo("#cardLinhaDoTempo", {
  delay: 0,
  opacity: 0,
  y: -100,
   
}, {
  opacity: 1,
  duration: 0.5,
  delay: 0.4,
  
  y: 0,
  ease: "power1.inOut",
  scrollTrigger: {
    trigger: "#cardLinhaDoTempo",
    start: "top 90%", 
    end: "bottom bottom", 
    scrub: false,
    markers: false, 
    
  }
});



gsap.fromTo("#car1", {
  delay: 1,
  opacity: 0,
  xPercent: -100,
   
}, {
  opacity: 1,
  duration: 0.5,
  delay: 0.2,
  
  xPercent: 0,
  ease: "power1.inOut",
  scrollTrigger: {
    trigger: "#car1",
    start: "top 70%", 
    end: "bottom bottom", 
    scrub: false,
    markers: false, 
    
  }
});

gsap.fromTo("#car4", {
  delay: 0,
  opacity: 0,
  xPercent: -100,
   
}, {
  opacity: 1,
  duration: 0.5,
  delay: 0.2,
  
  xPercent: 0,
  ease: "power1.inOut",
  scrollTrigger: {
    trigger: "#car1",
    start: "top 70%", 
    end: "bottom bottom", 
    scrub: false,
    markers: false, 
    
  }
});

gsap.fromTo("#car2", {
  delay: 0,
  opacity: 0,
  yPercent: -100,
   
}, {
  opacity: 1,
  duration: 0.5,
  delay: 0.2,
  
  yPercent: 0,
  ease: "power1.inOut",
  scrollTrigger: {
    trigger: "#car1",
    start: "top 70%", 
    end: "bottom bottom", 
    scrub: false,
    markers: false, 
    
  }
});

gsap.fromTo("#car5", {
  delay: 0,
  opacity: 0,
  yPercent: 100,
   
}, {
  opacity: 1,
  duration: 0.5,
  delay: 0.2,
  
  yPercent: 0,
  ease: "power1.inOut",
  scrollTrigger: {
    trigger: "#car1",
    start: "top 70%", 
    end: "bottom bottom", 
    scrub: false,
    markers: false, 
    
  }
});


gsap.fromTo("#car3", {
  delay: 0,
  opacity: 0,
  xPercent: 100,
   
}, {
  opacity: 1,
  duration: 0.5,
  delay: 0.2,
  
  xPercent: 0,
  ease: "power1.inOut",
  scrollTrigger: {
    trigger: "#car1",
    start: "top 70%", 
    end: "bottom bottom", 
    scrub: false,
    markers: false, 
    
  }
});

gsap.fromTo("#car6", {
  delay: 0,
  opacity: 0,
  xPercent: 100,
   
}, {
  opacity: 1,
  duration: 0.5,
  delay: 0.2,
  
  xPercent: 0,
  ease: "power1.inOut",
  scrollTrigger: {
    trigger: "#car1",
    start: "top 70%", 
    end: "bottom bottom", 
    scrub: false,
    markers: false, 
    
  }
});



gsap.fromTo("#cardLocaliza1", {
  delay: 0,
  opacity: 0,
  yPercent: -100,
   
}, {
  opacity: 1,
  duration: 0.5,
  delay: 0.2,
  
  xyPercent: 0,
  ease: "power1.inOut",
  scrollTrigger: {
    trigger: "#cardLocaliza1",
    start: "top 70%", 
    end: "bottom bottom", 
    scrub: false,
    markers: false, 
    
  }
});

gsap.fromTo("#cardLocaliza2", {
  delay: 0,
  opacity: 0,
  yPercent: -100,
   
}, {
  opacity: 1,
  duration: 0.5,
  delay: 0.2,
  
  xyPercent: 0,
  ease: "power1.inOut",
  scrollTrigger: {
    trigger: "#cardLocaliza1",
    start: "top 70%", 
    end: "bottom bottom", 
    scrub: false,
    markers: false, 
    
  }
});



gsap.fromTo("#svgsPrivate", {
  delay: 0,
  opacity: 0,
  xPercent: 0,
   
}, {
  opacity: 1,
  duration: 0.5,
  delay: 0.7,
  
  xPercent: 30,
  ease: "power1.inOut",
  scrollTrigger: {
    trigger: "#svgsPrivate",
    start: "top 70%", 
    end: "bottom bottom", 
    scrub: false,
    markers: false, 
    
  }
});

gsap.fromTo("#PrivateLeft", {
  delay: 0,
  opacity: 0,
  xPercent: 0,
   
}, {
  opacity: 1,
  duration: 0.5,
  delay: 0.7,
  
  xPercent: -30,
  ease: "power1.inOut",
  scrollTrigger: {
    trigger: "#PrivateLeft",
    start: "top 70%", 
    end: "bottom bottom", 
    scrub: false,
    markers: false, 
    
  }
});


gsap.fromTo("#privateimg", {
  delay: 0,
  opacity: 0,
  yPercent: 0,
   
}, {
  opacity: 1,
  duration: 0.5,
  delay: 0.7,
  
  yPercent: -30,
  ease: "power1.inOut",
  scrollTrigger: {
    trigger: "#privateimg",
    start: "top 70%", 
    end: "bottom bottom", 
    scrub: false,
    markers: false, 
    
  }
});


gsap.fromTo("#cont1", {
  delay: 0,
  opacity: 0,
  y: 0,
   
}, {
  opacity: 1,
  duration: 0.5,
  delay: 0.4,
  
  y: 100,
  ease: "power1.inOut",
  scrollTrigger: {
    trigger: "#cont1",
    start: "top 90%", 
    end: "bottom bottom", 
    scrub: false,
    markers: false, 
    
  }
});


let divs = document.querySelectorAll('.GradientCertificacao');
divs.forEach(function(div) {
    div.addEventListener('mouseover', function() {
        let img = div.querySelector('.img-hidden');
        console.log("Os ETs existem");
        console.log(img);
        gsap.to(img, {rotation: -30, scale:1, duration: 0.4});
    });

    div.addEventListener('mouseout', function() {
        let img = div.querySelector('.img-hidden');
        gsap.to(img, {rotation: 0, scale:0, duration: 0.4});
    });
});

