export default function handlePasswordModal() {
  const eyeOn = document.querySelector("[data-eyeOn]");
  const eyeOff = document.querySelector("[data-eyeOff]");
  const buttonEye = document.querySelector("button");
  const inputValue = document.querySelector(".input1");
  //const inputValue2 = document.querySelector(".input2");
  const inputSenhaAtual = document.querySelector(".inputSenhaAtual");
  const formSenha = document.querySelector("form");
  const textError = document.querySelector("[data-textError]");
  const loginPage = document.querySelector("[data-loginPage]");

  const hiddenEye = () => {
    buttonEye?.addEventListener("click", () => {
      if (inputValue.type === "password") inputValue.type = "text";
      else inputValue.type = "password";
      eyeOn?.classList.toggle("hidden");
      eyeOff?.classList.toggle("hidden");
    });
  };

  hiddenEye();



  const enviaFormLogin = () => {
    
    formSenha?.addEventListener("submit", (event) => {
      event.preventDefault();

      // window.introHome.kill()
      
      window.introHome.pause(0, false);
      window.introHome1.pause(0, false);
      window.introHome2.pause(0, false);
      window.introHome3.pause(0, false)
      
      
      //window.introTextHome.pause(0, false)

      if (inputValue?.value === inputSenhaAtual?.value) {
        //window.introHome.timeScale(introHome.timeScale() || 0).resume()
        window.introHome.play()
        window.introHome1.play()
        window.introHome2.play()
        window.introHome3.play()
        
          textError?.classList.add("hidden");
          loginPage?.classList.toggle("hidden");
        
        //window.introTextHome.timeScale(introTextHome.timeScale() || 1).resume()
        
        
        // window.introHome1.play();
      }
      if (inputValue?.value !== inputSenhaAtual?.value) {
        textError?.classList.remove("hidden");
        setTimeout(() => {
          textError?.classList.add("hidden");
      }, 2000);
      }
    });
  };

  enviaFormLogin();
}

/*

  const hiddenEye = () => {
    buttonEye?.addEventListener("click", () => {
      eyeOn?.classList.toggle("hidden");
      eyeOff?.classList.toggle("hidden");
      inputValue2.value = `${inputValue.value}`;
      inputValue2?.classList.toggle("hidden");
      inputValue?.classList.toggle("hidden");
    });
  };

  hiddenEye();

  const enviaFormLogin = () => {
    formSenha?.addEventListener("submit", (event) => {
      event.preventDefault();

      window.introHome.pause(0, false);
      window.introHome1.pause(0, false);

      if (inputValue?.value === inputSenhaAtual?.value) {
        textError?.classList.add("hidden");
        loginPage?.classList.toggle("hidden");
        inputValue2.value = inputValue.value;
        window.introHome.play();
        window.introHome1.play();
      }
      if (inputValue?.value !== inputSenhaAtual?.value) {
        textError?.classList.remove("hidden");
        return (inputValue2.value = inputValue.value);
      }
    });
  };

  inputValue2?.addEventListener("blur", function () {
    this.value = "";
  });

*/
