import axios from "axios";
import axiosConfig from "./modules/axiosConfig";
import PageHome from "./pages/Home.js";
import PageLogin from "./pages/Login";
import UseMouseOrKeyboard from "./modules/UseMouseOrKeyboard";
import DesignSystem from "./DesignSystem/DesignSystem";
import { register } from "swiper/element/bundle";
import logs from "./utils/logs";
import ripple from "./modules/ripple";
import formErros from "./modules/formErros";
import animaAoScroll from "./animation/animaAoScroll";
import animateSetup from "./animation/animateSetup";
import animaParceiros from "./lubrisint/animaParceiros";
import animaCertificate from "./lubrisint/animaCertificate";
import animaOffice from "./lubrisint/animaOffice";
import animaIntro from "./lubrisint/animaIntro";
import animaMouse from "./lubrisint/animaMouse";
import animaSectionsGsap from "./lubrisint/animaSectionsGsap";
import handlePasswordModal from "./lubrisint/modalSenha";
import animateFrom from "./animation/animateFrom";

// import SvgUse from "./utils/SvgUse";

// Init
GLOBAL.DesignSystem = DesignSystem({});
register(); // Swiper Web Component
animaParceiros();
animaCertificate();
animaOffice();
animaIntro();
animaMouse();

// Config
axiosConfig({ csrf: GLOBAL.context?.csrf }, axios);
UseMouseOrKeyboard();
ripple();
formErros();
animateSetup();
animaAoScroll();
//animateFrom();

// Pages
PageHome();
PageLogin()?.init();
animaSectionsGsap();
handlePasswordModal();
// utils logs
logs();



document.querySelector('input').addEventListener('input',async function() {
  console.log("EUUUUUUUUUUUUUUU")
  if (this.value !== '') {
      console.log(this.style.color)
      this.style.color = 'white';
  } 
});


function pageOffice() {
  const swiperEl = document.querySelector(".swiper-cards");
  if (!swiperEl) return;
  Object.assign(swiperEl, {
    grabCursor: true,
    effect: "creative",
    // reverseDirection: true,
    initialSlide: 1,
    rewind: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: document.querySelector(".swiper-office-next"),
      prevEl: document.querySelector(".swiper-office-prev"),
    },
    creativeEffect: {
      prev: {
        shadow: true,
        translate: [40, -40, -100],
      },
      next: {
        translate: ["30%", 0, 0],
        opacity: 0,
      },
    },
    injectStyles: [
      `
    :host(.swiper-cards) .swiper-wrapper {
        overflow: initial !important;
    }

    :host(.swiper-cards) .swiper   {
        overflow: initial !important;
    }
 
    `,
    ],
  });
  const textEL = document.getElementById("swiper-cards-text");
  const countEL = document.getElementById("swiper-cards-count");
  const progress = document.querySelector(
    "#swiper-cards-progress .animationProgress"
  );

  function setTextAndCount(event) {
    const detail = event.detail.at();
    const active = detail.activeIndex;
    const slides = detail.slides;
    const activeEl = slides.at(active);

    const percent = ((active + 1) / slides.length) * 100;

    countEL.innerText = `${active + 1}/${slides.length}`;
    textEL.innerText = activeEl?.getAttribute("data-text");
    progress.style.width = `${percent}%`;

    // dividir e gerar a porcentagem, e mduar a porcentagem
    /// transition no elemento
  }

  swiperEl.addEventListener("swiper-init", setTextAndCount);

  swiperEl.initialize();

  swiperEl.addEventListener("swiper-slidechange", setTextAndCount);
}

pageOffice();

function swiperDetails() {
  const swiperEl = document.querySelector(".swiperDetails");
  if (!swiperEl) return;

  Object.assign(swiperEl, {
    navigation: {
      nextEl: document.querySelector(".swiper-details-next"),
      prevEl: document.querySelector(".swiper-details-prev"),
    },
  });

  swiperEl.initialize();
}

swiperDetails();
