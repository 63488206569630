export default function animaParceiros() {
  const itensHover = document.querySelectorAll(".certificate");

  ///Animate hover page certificados
  itensHover.forEach((i) => {
    i.addEventListener("mouseenter", () => {
      disableItems();
      const text = i.querySelector(".text-certificate");
      
      i.classList.remove("disable");
      i.classList.add("active");
      gsap.fromTo(".text-certificate", {
        delay: 0,
	      opacity: 0,
	      
      }, {
        opacity: 1,
	      duration: 0.4,
        delay: 0.2,
	      
	      ease: "power1.inOut",
      })
      
      // i.setAttribute('js-anima')
      // i.setAttribute('data-anima', 'zoom');
      // i.setAttribute('data-to', JSON.stringify({"duration":0.4, "delay":0.8}));
      
      text.classList.remove("text-disable");
    });
  });

  // gsap.fromTo(".certificate", {
	// 	delay: 1,
	// 	opacity: 0,
	// 	xPercent: 0,
	// }, {
	// 	opacity: 1,
	// 	duration: 0.4,
	// 	delay: 0.5,
	// 	xPercent: 1,
	// 	ease: "power1.inOut",
	// 	scrollTrigger: {
	// 		trigger: ".certificate",
	// 		start: "top 70%", 
	// 		end: "bottom bottom", 
	// 		scrub: false, 
			
	// 	}
	// });


  const section = document.querySelector('#login-home');

  section.addEventListener('wheel', (event) => {
    event.preventDefault();
  });




  const disableItems = () => {
    itensHover.forEach((i) => {
      const text = i.querySelector(".text-certificate");
      i.classList.remove("active");
      i.classList.add("disable");
      text.classList.remove("text-active");
      text.classList.add("text-disable");
    });
  };
  //////

  ///Animate hover title tecnologia
  const idSection = document.querySelector("#tecnologia");

  idSection?.addEventListener("mouseover", (event) => {
    const text = document.querySelectorAll("[js-animaText]");
    text.forEach((el) => {
      el.classList.toggle("animaTextActive");
    });
  });
}
