export default function animaSectionsGsap() {
  gsap.from(".bg-intro", {
    scrollTrigger: {
      trigger: ".bg-intro",
      toggleActions: "play pause play reverse",
      start: "0 80%",
    },
    // ease: "power2.inOut",
    backgroundImage:
      "radial-gradient(-60% -60% at -20% -20%, rgba(23, 45, 143, 1) 0, transparent 100%)",
    duration: 2,
  });

  gsap.from(".bg-time-1", {
    scrollTrigger: {
      trigger: ".bg-time-1",
      toggleActions: "play pause play reverse",
      start: "0 80%",
    },
    // ease: "power2.inOut",
    backgroundImage:
      "radial-gradient(-60% -60% at -20% -20%, rgba(23, 45, 143, 1) 0, transparent 100%)",
    duration: 4,
  });

  gsap.from(".bg-time-2", {
    scrollTrigger: {
      trigger: ".bg-time-2",
      toggleActions: "play pause play reverse",
      start: "0 80%",
    },
    // ease: "power2.inOut",
    backgroundImage:
      "radial-gradient(-60% -60% at -20% -20%, rgba(23, 45, 143, 1) 0, transparent 100%)",
    duration: 6,
  });

  gsap.from(".bg-office", {
    scrollTrigger: {
      trigger: ".bg-office",
      toggleActions: "play pause play reverse",
      start: "0 80%",
    },
    // ease: "power2.inOut",
    backgroundImage:
      "radial-gradient(-60% -60% at -20% -20%, rgba(23, 45, 143, 1) 0, transparent 100%)",
    duration: 8,
  });

  gsap.from(".bg-certificate", {
    scrollTrigger: {
      trigger: ".bg-certificate",
      toggleActions: "play pause play reverse",
      start: "0 80%",
    },
    // ease: "power2.inOut",
    backgroundImage:
      "radial-gradient(-60% -60% at -20% -20%, rgba(23, 45, 143, 1) 0, transparent 100%)",
    duration: 10,
  });

  gsap.from(".bg-footer", {
    scrollTrigger: {
      trigger: ".bg-footer",
      toggleActions: "play pause play reverse",
      start: "0 80%",
    },
    // ease: "power2.inOut",
    backgroundImage:
      "radial-gradient(-60% -60% at -20% -20%, rgba(23, 45, 143, 1) 0, transparent 100%)",
    duration: 12,
  });
}
