import { find } from 'utils/dom'
import gsap from 'gsap'
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);




export default function animaIntro() {


  
  // gsap.fromTo("#button-intro", {
  //   delay: 1,
    
  //   xPercent: -30,
     
  //  }, {
    
  //   duration: 1,
  //   delay: 1.6,
    
  //   xPercent: 110,
  //   ease: "power1.inOut",
     
  //  }, {
  //   duration: 1,
  //   delay: 1.6,
    
  //   xPercent: -110,
  //   ease: "power1.inOut",
  //   repeat: -1,




  //  });


  let tl = gsap.timeline({repeat: -1, yoyo: true});

  
  tl.to("#button-intro", {
   xPercent: -0,
   duration: 0.7,
   ease: "power1.inOut"
  })
  .delay(1) 
  .to("#button-intro", {
   xPercent: 100,
   duration: 0.7,
   ease: "power1.inOut"
  }).delay(1) 
  .to("#button-intro", {
   xPercent: 100,
   duration: 0.7,
   ease: "power1.inOut"
  });

























  let tl2 = gsap.timeline({repeat: -1, yoyo: true});

  
  tl2.to("#button-intro-mobile", {
   xPercent: -0,
   duration: 0.7,
   ease: "power1.inOut"
  })
  .delay(1) 
  .to("#button-intro-mobile", {
   xPercent: 100,
   duration: 0.7,
   ease: "power1.inOut"
  }).delay(1) 
  .to("#button-intro-mobile", {
   xPercent: 100,
   duration: 0.7,
   ease: "power1.inOut"
  });
  

   
   
   

  
  
  
  const buttonItro = document.querySelectorAll("[data-buttonIntro]");
  const textHiddenTop = document.querySelectorAll("[data-textHiddenTop]");
  const textHiddenBottom = document.querySelectorAll("[data-textHiddenBottom]");

  // const showText = () => {
  //   const btnHidden = document.querySelectorAll("[data-buttonHidden]");
  //   const btnShow = document.querySelectorAll("[data-buttonShow]");


    
  //   // btnHidden.forEach((top) => {
  //   //   setInterval(() => {
  //   //     top.classList.toggle("btnActive1");
  //   //   }, 2200);
  //   // });

  //   // btnShow.forEach((bottom) => {
  //   //   setInterval(() => {
  //   //     bottom.classList.toggle("btnActive1");
  //   //   }, 2200);
  //   // });
  //   //hiddenTitle();
  //   //hiddenTitleBottom();
  // };


    // let titleLg = document.querySelector('.titlelg');

    // console.log(titleLg)

    // window.addEventListener('resize', function() {
    //     if (window.innerWidth > 768) { 
    //         if (!document.body.contains(titleLg)) {
    //             document.body.appendChild(titleLg);
    //         }
    //     } else {
    //         if (document.body.contains(titleLg)) {
    //             document.body.removeChild(titleLg);
    //             console.log(titleLg)
    //         }
    //     }
    // });



  // const hiddenTitleBottom = () => {
  //   textHiddenBottom.forEach((i) => {
  //     setInterval(() => {
  //       i.classList.toggle("itemTextDisable");
  //     }, 2300);
  //   });
  // };

  

  // const hiddenTitle = () => {
  //   textHiddenTop.forEach((i) => {
  //     setInterval(() => {
  //       i.classList.toggle("itemTextDisable");
  //     }, 2000);
  //   });


  	//novo
	const textIntroWrapper = find('[js-text-intro]')


  async function loopReset() {
		const texts = ['de alta performance', 'e de grau alimentício no Brasil']
		const text1 = find('[js-text-1]', textIntroWrapper)
		const text2 = find('[js-text-2]', textIntroWrapper)

		let count = 0



    console.log(text1)
		text1.innerText = texts[count]

		const counter = () => {
			if (count === 1) {
				count = 0
			} else {
				count++
			}
		}

		gsap.set(text1, { yPercent: 0, opacity: 1 })
		gsap.set(text2, { yPercent: -120, opacity: 0 })

		const duration = 0.5
		const ease = 'none'
		const delay = 1.6


		const fillText = (variable) => {
			counter()
			variable.innerText = texts[count]
		}

		const tl = gsap
			.timeline({ overwrite: true, delay: -1, repeat: -1, paused: true })
			// texto 1 desceu
			.to(text1, { yPercent: 230, opacity: 0, duration, ease, delay }, '@inicial')
			// texto 2 recebeu o texto
			.call(fillText, [text2], '<')
			// texto 2 ativo
			.to(text2, { yPercent: 0, opacity: 1, duration, ease, delay }, '@inicial')
			// text 1 pulou pra cima
			.set(text1, { yPercent: -110, opacity: 0 })
			// setei texto no texto 1
			.call(fillText, [text1], '<')
			// texto 2 desceu
			.to(text2, { yPercent: 110, opacity: 0, duration, ease, delay }, '@medio')
			// texto 1 ativo
			.to(text1, { yPercent: 0, opacity: 1, duration, ease, delay }, '@medio')

		tl.timeScale(0.9).play()
		window.tlIntro = tl

    //showText();

    

  }


    




		// await new Promise((resolve) => setTimeout(resolve, 3000))

		// headersCollections.forEach((item) => {
		// 	//item.classList.remove('HiddenOpacity')
		// 	item.classList.add('hidden')
		// 	window.introAnima.kill()
		// })
		// if (n >= 0 && n < headersCollections.length) {
		// 	headersCollections[n].classList.remove('hidden')
		// 	//headersCollections[n].classList.add('HiddenOpacity')
		// 	//headersCollections[n].classList.add('HiddenText')
		// 	window.introAnima.timeScale(0.5).restart(true)
		// 	window.introAnima.resume()
		// }

		// n = (n + 1) % 5
		// console.log(headersCollections[n])
		// loopReset()

    

    
	

  const textIntroWrapperMobile = find('[js-text-intro-small]')

    async function loopResetSmall() {
      const texts = ['de alta performance', 'e de grau alimentício no Brasil']
      const text1 = find('[js-text-1-small]', textIntroWrapperMobile)
      const text2 = find('[js-text-2-small]', textIntroWrapperMobile)
  
      let count = 0
  
  
  
      console.log(text1)
      text1.innerText = texts[count]
  
      const counter = () => {
        if (count === 1) {
          count = 0
        } else {
          count++
        }
      }
  
      gsap.set(text1, { yPercent: 0, opacity: 1 })
      gsap.set(text2, { yPercent: -110, opacity: 0 })
  
      const duration = 0.5
      const ease = 'none'
      const delay = 1.6
  
  
      const fillText = (variable) => {
        counter()
        variable.innerText = texts[count]
      }
  
      const tl = gsap
        .timeline({ overwrite: true, delay: 1.2, repeat: -1, paused: true })
        // texto 1 desceu
        .to(text1, { yPercent:  230, opacity: 0, duration, ease, delay }, '@inicial')
        // texto 2 recebeu o texto
        .call(fillText, [text2], '<')
        // texto 2 ativo
        .to(text2, { yPercent: -49, opacity: 1, duration, ease, delay }, '@inicial')
        // text 1 pulou pra cima
        .set(text1, { yPercent: -110, opacity: 0 })
        // setei texto no texto 1
        .call(fillText, [text1], '<')
        // texto 2 desceu
        .to(text2, { yPercent: 110, opacity: 0, duration, ease, delay }, '@medio')
        // texto 1 ativo
        .to(text1, { yPercent: 0, opacity: 1, duration, ease, delay }, '@medio')
  
      tl.timeScale(0.9).play()
      window.tlIntro = tl

    }


    loopReset()
    loopResetSmall()




   



    



    

  

	
  
};



  


/*
export default function animaIntro() {
  const buttonItro = document.querySelectorAll("[data-buttonIntro]");
  const textHiddenTop = document.querySelectorAll("[data-textHiddenTop]");
  const textHiddenBottom = document.querySelectorAll("[data-textHiddenBottom]");

  const showText = () => {
    buttonItro.forEach((btn) => {
      btn.addEventListener("click", () => {
        const btnHidden = btn.querySelectorAll("[data-buttonHidden]");
        const btnShow = btn.querySelectorAll("[data-buttonShow]");

        btnHidden.forEach((item1) => {
          btnShow.forEach((item2) => {
            item1.classList.toggle("btnActive1");
            item2.classList.toggle("btnActive1");
            hiddenTitle();
            hiddenTitleBottom();
          });
        });
      });
    });
  };

  const hiddenTitle = () => {
    textHiddenTop.forEach((i) => {
      i.classList.toggle("itemSvgDisable");
    });
  };

  const hiddenTitleBottom = () => {
    textHiddenBottom.forEach((i) => {
      i.classList.toggle("itemSvgDisable");
    });
  };

  showText();

  setInterval(() => {
    console.log("Delayed for 1 second.");
  }, 3000);
}
*/