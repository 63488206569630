export default function animaCertificate() {
    const items = document.querySelectorAll('.item-certificate')
    const textNormal = document.querySelectorAll('.GradientCertificacao')

    items.forEach(i=>{
        i.addEventListener('click', ()=>{
        removeActive()
            i.classList.toggle('GradientCertificacaoActive')
        })
    })

    const removeActive = () =>{
        items.forEach(i=>{
            i.classList.remove('GradientCertificacaoActive')
        })
    }

    const effectText =(item)=>{
        item.forEach(i =>{
            i.addEventListener('mouseover', ()=>{
                const itemText = i.querySelector('.textoNumberNormal')
                itemText?.classList.add('textoNumberActive')
                itemText?.classList.remove('textoNumberNormal')
            })
        })
    
        item.forEach(i =>{
            i.addEventListener('mouseout', ()=>{
                const itemText = i.querySelector('h2')
                itemText?.classList.remove('textoNumberActive')
                itemText?.classList.add('textoNumberNormal')
            })
        })
    }

effectText(textNormal)
effectText(items)



}