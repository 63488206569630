import { find } from "utils/dom";
import  gsap  from "gsap";



export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return

	console.log('Home')






	
}